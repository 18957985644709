import { BuPath } from 'constants/buPath';
import {
  LabelFieldMapping,
  POFieldMapping,
  PORateKeyMapping,
  PORateObjectKeyMapping,
} from 'constants/poRates.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import _ from 'lodash';
import { isEmptyVal } from 'utils/utils';
import * as Yup from 'yup';
import { getPORateObject } from '../utils';

export const generateSchema = (isJobAuthUnitPriceTask, isDistribution) => {
  let upTaskSchema = Yup.array().of(
    Yup.object().shape({
      rateschecode: Yup.string().required('This field is required'),
      code: Yup.string().required('This field is required'),
      activitynum: Yup.string().required('This field is required'),

      workDesc: Yup.string().optional().nullable(true),
      unitmeasure: Yup.string().optional().nullable(true),
      quantity: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
      unitPriceEqptLabor: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
      unitPriceLabor: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .min(0, 'Value must not be negative')
        .transform((_, val) => (val === '' ? null : Number(val))),
      unitPriceEqpt: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
      unitPriceMatl: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
      totalPrice: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
      lineAmount: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
      hours: Yup.number()
        .typeError('Must be a number')
        .optional()
        .nullable(true)
        .transform((_, val) => (val === '' ? null : Number(val))),
    }),
  );

  if (isJobAuthUnitPriceTask) {
    upTaskSchema = Yup.array().of(
      Yup.object().shape({
        rateschecode: Yup.string()
          .optional()
          .nullable()
          .transform((_, val) => (val === '' ? null : Number(val))),
        code: Yup.string().required('This field is required'),
        activitynum: Yup.string()
          .optional()
          .transform((_, val) => (val === null ? '' : '' + val)),

        workDesc: Yup.string().optional().nullable(true),
        unitmeasure: Yup.string().optional().nullable(true),
        quantity: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        unitPriceEqptLabor: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        unitPriceLabor: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .min(0, 'Value must not be negative')
          .transform((_, val) => (val === '' ? null : Number(val))),
        unitPriceEqpt: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        unitPriceMatl: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        totalPrice: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        lineAmount: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        hours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
      }),
    );
  }
  return Yup.object().shape({
    workStartDate: Yup.date().optional(),
    workEndDate: Yup.date().optional(),
    weekEndDate: Yup.date().optional(),
    contRefNumeric: Yup.string()
      .max(10, 'Maximum character limit of 10 has exceeded.')
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    vendorTimesheet: Yup.string()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val))
      .max(25, 'Maximum character limit of 25 has exceeded.'),
    comments: Yup.string()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val))
      .max(255, 'Maximum character limit of 255 has exceeded.'),
    uvl: Yup.boolean().required(),
    uvlEstimateNo: Yup.boolean().required(),
    submittedByAep: Yup.boolean().nullable(true),

    // Distribution Timesheet fields
    crewType: Yup.string()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    crewNmbr: Yup.string()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val))
      .max(3, 'Length must not be more than 3 characters')
      .optional(),
    workAreaId: Yup.number()
      .optional()
      .typeError('Must be a number')
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    worktype: Yup.string()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    ocNmbr: Yup.number()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    masterProjectNumber: Yup.string()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val))
      .max(150, 'Length must not be more than 150 characters')
      .optional(),
    authType: Yup.string().when([], {
      is: () => isDistribution === true,
      then: s => s.required('This field is required'),
      otherwise: s => s,
    }),
    authNmbr: Yup.string()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    circuitnum: Yup.string()
      .optional()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    subcircuit: Yup.string()
      .optional()
      .max(2, 'Length must not be more than 2 characters')
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val)),
    milescomplete: Yup.number()
      .optional()
      .typeError('Must be a number')
      .min(0, 'Value must be greater than 0')
      .max(1000, 'Value must not be greater than 1000')
      .nullable(true)
      .transform((_, val) => (val === '' ? null : Number(val))),
    jobCity: Yup.string()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val))
      .max(35, 'Length must not be more than 35 characters')
      .optional(),
    jobDescription: Yup.string()
      .nullable(true)
      .transform((_, val) => (val === '' ? null : val))
      .max(50, 'Length must not be more than 50 characters')
      .optional(),

    timesheetAccounts: Yup.array().of(
      Yup.object().shape({
        workorder: Yup.string().optional().nullable(true),
        abmsProject: Yup.string().optional().nullable(true),
        accountNmbr: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        projectBusinessUnit: Yup.string().optional().nullable(true),
        glbu: Yup.string()
          .max(5, 'Length must not be more than 5 characters')
          .optional()
          .nullable(true),
        dept: Yup.string()
          .max(5, 'Length must not be more than 5 characters')
          .optional()
          .nullable(true),
        costComponent: Yup.string().optional().nullable(true),
        abmsActivity: Yup.string().optional().nullable(true),
      }),
    ),
    /* ******************************* */

    [SERVICE_TYPES.LABOR]: Yup.array().of(
      Yup.object().shape({
        rateschecode: Yup.string().required('This field is required'),
        laborCode: Yup.string().required('This field is required'),
        laborCategory: Yup.string().optional().nullable(true),
        activitynum: Yup.string().required('This field is required'),

        workDesc: Yup.string()
          .optional()
          .nullable(true)
          .max(300, 'Length must not be more than 300 characters'),
        employeeId: Yup.string()
          .optional()
          .nullable(true)
          .max(15, 'Max character limit is 15'),
        employeeName: Yup.string()
          .optional()
          .nullable(true)
          .max(30, 'Max character limit is 30'),
        shift: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),

        regLaborHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        otLaborHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        dtLaborHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        holidayPayHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        travelHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        peakSeasonHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        emerHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        standbyHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        mobUnits: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        demobUnits: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        perDiemUnits: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        dailyHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        weeklyHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        monthlyHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        rhxHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        dtxHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
      }),
    ),
    [SERVICE_TYPES.EQUIPMENT]: Yup.array().of(
      Yup.object().shape({
        rateschecode: Yup.string().required('This field is required'),
        equipCode: Yup.string().required('This field is required'),
        equipCategory: Yup.string().optional().nullable(true),
        activitynum: Yup.string().required('This field is required'),

        workDesc: Yup.string()
          .optional()
          .nullable(true)
          .max(300, 'Length must not be more than 300 characters'),
        equipUnitmeasure: Yup.string().optional().nullable(true),
        owned: Yup.string().optional().nullable(true),
        eqptype: Yup.string().optional().nullable(true),
        equipId: Yup.string().when(['equipmentRentalUnits'], {
          is: equipmentRentalUnits => equipmentRentalUnits > 0,
          then: Yup.string().required(
            'This field is required if Equipment Rental Unit having positive value',
          ),
        }),

        equipmentRentalUnits: Yup.number()
          .typeError('Must be a number')
          .positive('Must be greater than 0')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        opHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        stHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        otHours: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        miles: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        mobUnits: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        demobUnits: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
      }),
    ),
    [SERVICE_TYPES.REIMBURSABLE_MARKUP]: Yup.array().of(
      Yup.object().shape({
        rateschecode: Yup.string().required('This field is required'),
        reimbCode: Yup.string().required('This field is required'),
        activitynum: Yup.string().required('This field is required'),

        workDesc: Yup.string()
          .optional()
          .nullable(true)
          .max(300, 'Length must not be more than 300 characters'),
        reimbUnitmeasure: Yup.string().optional().nullable(true),
        reimbQuantity: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        reimbPrice: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
      }),
    ),
    [SERVICE_TYPES.UNIT_PRICE_TASK]: upTaskSchema,
    [SERVICE_TYPES.CU]: Yup.array().of(
      Yup.object().shape({
        rateschecode: Yup.string().required('This field is required'),
        cuGroupCode: Yup.string().required('This field is required'),
        cuUnitmeasure: Yup.string().optional().nullable(true),
        activitynum: Yup.string().required('This field is required'),
        workDesc: Yup.string()
          .optional()
          .nullable(true)
          .max(300, 'Length must not be more than 300 characters'),
        quantity: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        installPrice: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        removePrice: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .min(0, 'Value must not be negative')
          .transform((_, val) => (val === '' ? null : Number(val))),
        transferPrice: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        smallJobAmount: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        travelAmount: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        deEnergizedAmount: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
      }),
    ),
    [SERVICE_TYPES.LUMPSUM]: Yup.array().of(
      Yup.object().shape({
        activitynum: Yup.string().required('This field is required'),
        amount: Yup.number()
          .typeError('Must be a number')
          .optional()
          .nullable(true)
          .transform((_, val) => (val === '' ? null : Number(val))),
        workDesc: Yup.string()
          .optional()
          .nullable(true)
          .max(300, 'Length must not be more than 300 characters'),
      }),
    ),
  });
};

export const regenerateFormValues = (
  payloadData,
  purchaseOrder,
  purchaseOrderObject,
) => {
  const poCostActivityList = purchaseOrder?.[PORateKeyMapping['poActivity']];

  payloadData?.[SERVICE_TYPES.LABOR]?.forEach(obj => {
    const rateObject = getPORateObject(
      purchaseOrderObject,
      SERVICE_TYPES.LABOR,
      obj.rateschecode,
      obj.laborCode,
      obj.laborCategory,
    );

    if (!isEmptyVal(rateObject)) {
      // Rate id
      obj[PORateObjectKeyMapping[SERVICE_TYPES.LABOR]] =
        rateObject[PORateObjectKeyMapping[SERVICE_TYPES.LABOR]];

      // Rate Schedule
      obj.rateschecode =
        rateObject.rateschecode === 'null' ? null : rateObject.rateschecode;
      obj.rateschDescription = rateObject.rateschedescription;

      // Labor Code
      obj.laborCode =
        rateObject[POFieldMapping.laborCode] === 'null'
          ? null
          : rateObject[POFieldMapping.laborCode];
      obj.laborDescription = rateObject.description;

      // Labor Category
      obj.laborCategory =
        rateObject[POFieldMapping.laborCategory] === 'null'
          ? null
          : rateObject[POFieldMapping.laborCategory];
    }

    const filteredCostActivityList = poCostActivityList?.filter(
      laborObj =>
        String(laborObj[PORateObjectKeyMapping['activity']]) ===
        String(obj.activitynum),
    );

    if (filteredCostActivityList.length) {
      const activityObject = filteredCostActivityList[0];

      // Cost activity id
      obj[PORateObjectKeyMapping['activity']] =
        activityObject[PORateObjectKeyMapping['activity']];

      // Activity Number
      obj.activitynum =
        activityObject.activitynum === 'null'
          ? null
          : activityObject.activitynum;
      obj.activityDescription = activityObject.description;
    }
  });

  payloadData?.[SERVICE_TYPES.EQUIPMENT]?.forEach(obj => {
    const rateObject = getPORateObject(
      purchaseOrderObject,
      SERVICE_TYPES.EQUIPMENT,
      obj.rateschecode,
      obj.equipCode,
      obj.equipCategory,
    );

    if (!isEmptyVal(rateObject)) {
      // Rate id
      obj[PORateObjectKeyMapping[SERVICE_TYPES.EQUIPMENT]] =
        rateObject[PORateObjectKeyMapping[SERVICE_TYPES.EQUIPMENT]];

      // Rate Schedule
      obj.rateschecode =
        rateObject.rateschecode === 'null' ? null : rateObject.rateschecode;
      obj.rateschDescription = rateObject.rateschedescription;

      // Equipment Code
      obj.equipCode =
        rateObject[POFieldMapping.equipCode] === 'null'
          ? null
          : rateObject[POFieldMapping.equipCode];
      obj.description = rateObject.description;

      // Equipment Category
      obj.equipCategory =
        rateObject[POFieldMapping.equipCategory] === 'null'
          ? null
          : rateObject[POFieldMapping.equipCategory];
    }

    const filteredCostActivityList = poCostActivityList?.filter(
      eqPObj =>
        String(eqPObj[PORateObjectKeyMapping['activity']]) ===
        String(obj.activitynum),
    );
    if (filteredCostActivityList.length) {
      const activityObject = filteredCostActivityList[0];

      // Cost activity id
      obj[PORateObjectKeyMapping['activity']] =
        activityObject[PORateObjectKeyMapping['activity']];

      // Activity Number
      obj.activitynum =
        activityObject.activitynum === 'null'
          ? null
          : activityObject.activitynum;
      obj.activityDescription = activityObject.description;
    }
  });

  payloadData?.[SERVICE_TYPES.REIMBURSABLE_MARKUP]?.forEach(obj => {
    const rateObject = getPORateObject(
      purchaseOrderObject,
      SERVICE_TYPES.REIMBURSABLE_MARKUP,
      obj.rateschecode,
      obj.reimbCode,
      null,
    );

    if (!isEmptyVal(rateObject)) {
      // Rate id
      obj[PORateObjectKeyMapping[SERVICE_TYPES.REIMBURSABLE_MARKUP]] =
        rateObject[PORateObjectKeyMapping[SERVICE_TYPES.REIMBURSABLE_MARKUP]];

      // Rate Schedule
      obj.rateschecode =
        rateObject.rateschecode === 'null' ? null : rateObject.rateschecode;
      obj.rateschDescription = rateObject.rateschedescription;

      // Reimbursable Code
      obj.reimbCode =
        rateObject[POFieldMapping.reimbCode] === 'null'
          ? null
          : rateObject[POFieldMapping.reimbCode];
      obj.description = rateObject.description;
    }

    const filteredCostActivityList = poCostActivityList.filter(
      reimbObj =>
        String(reimbObj[PORateObjectKeyMapping['activity']]) ===
        String(obj.activitynum),
    );

    if (filteredCostActivityList.length) {
      const activityObject = filteredCostActivityList[0];

      // Cost activity id
      obj[PORateObjectKeyMapping['activity']] =
        activityObject[PORateObjectKeyMapping['activity']];

      // Activity Number
      obj.activitynum =
        activityObject.activitynum === 'null'
          ? null
          : activityObject.activitynum;
      obj.activityDescription = activityObject.description;
    }
  });

  payloadData?.[SERVICE_TYPES.UNIT_PRICE_TASK]?.forEach(obj => {
    let rateObject = getPORateObject(
      purchaseOrderObject,
      SERVICE_TYPES.UNIT_PRICE_TASK,
      obj.rateschecode,
      obj.code,
      null,
    );

    if (
      BuPath.DIST.includes(payloadData['bupath']) &&
      payloadData['authType']?.trim() === 'Unit Price' &&
      !isEmptyVal(payloadData['authNmbr'])
    ) {
      const codeField = LabelFieldMapping['codeLabel'];

      rateObject = purchaseOrderObject[
        PORateKeyMapping[SERVICE_TYPES.UNIT_PRICE_TASK]
      ].find(fObj => fObj[codeField] === obj.code);
    }

    if (!isEmptyVal(rateObject)) {
      // Rate id
      obj[PORateObjectKeyMapping[SERVICE_TYPES.UNIT_PRICE_TASK]] =
        rateObject[PORateObjectKeyMapping[SERVICE_TYPES.UNIT_PRICE_TASK]];

      // Rate Schedule
      obj.rateschecode =
        rateObject.rateschecode === 'null' ? null : rateObject.rateschecode;
      obj.rateschDescription = rateObject.rateschedescription;

      // Unit Price Task Code
      obj.code =
        rateObject[POFieldMapping.code] === 'null'
          ? null
          : rateObject[POFieldMapping.code];
      obj.description = rateObject.description;
    }

    const filteredCostActivityList = poCostActivityList.filter(
      upObj =>
        String(upObj[PORateObjectKeyMapping['activity']]) ===
        String(obj.activitynum),
    );

    if (filteredCostActivityList.length) {
      const activityObject = filteredCostActivityList[0];

      // Cost activity id
      obj[PORateObjectKeyMapping['activity']] =
        activityObject[PORateObjectKeyMapping['activity']];

      // Activity Number
      obj.activitynum =
        activityObject.activitynum === 'null'
          ? null
          : activityObject.activitynum;
      obj.activityDescription = activityObject.description;
    }
  });

  payloadData?.[SERVICE_TYPES.CU]?.forEach(obj => {
    const rateObject = getPORateObject(
      purchaseOrderObject,
      SERVICE_TYPES.CU,
      obj.rateschecode,
      obj.cuGroupCode,
      null,
    );
    if (!isEmptyVal(rateObject)) {
      // Rate id
      obj[PORateObjectKeyMapping[SERVICE_TYPES.CU]] =
        rateObject[PORateObjectKeyMapping[SERVICE_TYPES.CU]];

      // Rate Schedule
      obj.rateschecode =
        rateObject.rateschecode === 'null' ? null : rateObject.rateschecode;
      obj.rateschDescription = rateObject.rateschedescription;

      // CU Code
      obj.cuGroupCode =
        rateObject[POFieldMapping.cuCode] === 'null'
          ? null
          : rateObject[POFieldMapping.cuCode];
      // obj.description = rateObject.description;
    }

    const filteredCostActivityList = poCostActivityList.filter(
      upObj =>
        String(upObj[PORateObjectKeyMapping['activity']]) ===
        String(obj.activitynum),
    );

    if (filteredCostActivityList.length) {
      const activityObject = filteredCostActivityList[0];

      // Cost activity id
      obj[PORateObjectKeyMapping['activity']] =
        activityObject[PORateObjectKeyMapping['activity']];

      // Activity Number
      obj.activitynum =
        activityObject.activitynum === 'null'
          ? null
          : activityObject.activitynum;
      obj.activityDescription = activityObject.description;
    }
  });

  payloadData?.[SERVICE_TYPES.LUMPSUM]?.forEach(obj => {
    // Activity Number
    const filteredCostActivityList = poCostActivityList.filter(
      lumpObj =>
        String(lumpObj[PORateObjectKeyMapping['activity']]) ===
        String(obj.activitynum),
    );
    if (filteredCostActivityList.length) {
      const activityObject = filteredCostActivityList[0];

      // Cost activity id
      obj[PORateObjectKeyMapping['activity']] =
        activityObject[PORateObjectKeyMapping['activity']];

      // Activity Number
      obj.activitynum =
        activityObject.activitynum === 'null'
          ? null
          : activityObject.activitynum;
      obj.activityDescription = activityObject.description;
    }
  });
};

export const transformEmptyStringToNull = payloadData => {
  for (let field in payloadData) {
    if (_.isArray(payloadData[field]) && payloadData[field].length) {
      payloadData[field].forEach(obj => {
        transformEmptyStringToNull(obj);
      });
    } else {
      if (payloadData[field] === '') {
        payloadData[field] = null;
      }
    }
  }
};

export const transformNullToEmptyString = payloadData => {
  for (let field in payloadData) {
    if (_.isArray(payloadData[field]) && payloadData[field].length) {
      payloadData[field].forEach(obj => {
        transformNullToEmptyString(obj);
      });
    } else {
      if (payloadData[field] === null) {
        payloadData[field] = '';
      }
    }
  }
};
