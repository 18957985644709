import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/adminModuleService';
import { PROMISE_TRACKER } from 'constants/promiseTrackers';
import { trackPromise } from 'react-promise-tracker';
import {
  fetchWorkTypeDetails,
  fetchSelectedAdminWorkTypetList,
  setSelectedAdminWorkTypeRecords,
  fetchAdminWorkType,
  updateWorkTypeData,
  addWorkTypeData,
  deleteWorkTypeData,
  setAdminWorkTypeData,
  setWorkTypeDetails,
  setError,
  setUpdateFieldsList,
} from 'store/slices/AdminModules/workTypeSlice';
import { displayError } from 'components/common/Alert/ToastAlert';
import _ from 'lodash';

const workTypePaginationSelector = state => state.adminWorkType.pagination;
const trackWorkTypeDetails = (fn, ...args) =>
  trackPromise(fn(...args), PROMISE_TRACKER.getWorkType);

export function* getAdminWorkType(action) {
  const queryParams = _.cloneDeep(yield select(workTypePaginationSelector));

  try {
    const response = yield call(
      f => service.retrieveAdminWorkTypeData(queryParams),
      action.payload,
    );
    const { data } = response;
    data.pagination = { totalItems: data.count };
    yield put(setAdminWorkTypeData(data));
  } catch (e) {
    put(setError(true));
    displayError(
      'We are experiencing technical difficulties. Please come back shortly to try again !',
    );
  }
}

export function* updateWorkTypeDetails(action) {
  try {
    const response = yield call(
      f => service.updateWorkTypeDataDetails(action.payload),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
      yield call(f => getAdminWorkType(action));
    }
  } catch (e) {
    put(setError(true));
  }
}

export function* addWorkTypeDetails(action) {
  try {
    const response = yield call(
      f => service.addWorkTypeDataDetails(action.payload),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
      yield call(f => getAdminWorkType(action));
    }
  } catch (e) {
    put(setError(true));
  }
}

export function* deleteWorkTypeDetails(action) {
  try {
    const response = yield call(
      f => service.deleteWorkTypeDataDetails(action.payload),
      action.payload,
    );
    const { data } = response;
    if (action.payload.callback) {
      action.payload.callback(data);
      yield call(f => getAdminWorkType(action));
    }
  } catch (e) {
    put(setError(true));
  }
}

export function* getWorkTypeDetails() {
  try {
    const updatefieldsdata = yield call(trackWorkTypeDetails, f =>
      service.retrieveUpdateFieldsData(),
    );
    yield put(setUpdateFieldsList(updatefieldsdata));
  } catch (e) {
    put(setError(true));
  }
}

function* getSelectedAdminWorkTypeList(action) {
  try {
    const response = yield call(
      f => service.retrieveWorkTypeFilterList(action.payload),
      action.payload,
    );
    const { data } = response;
    yield put(setSelectedAdminWorkTypeRecords(data));
  } catch (e) {
    put(setError(true));
  }
}

function* watchWorkTypeDetails() {
  yield takeLatest(fetchWorkTypeDetails.type, getWorkTypeDetails);
}

function* watchUpdateWorkTypeData() {
  yield takeLatest(updateWorkTypeData.type, updateWorkTypeDetails);
}

function* watchAddWorkTypeData() {
  yield takeLatest(addWorkTypeData.type, addWorkTypeDetails);
}

function* watchDeleteWorkTypeData() {
  yield takeLatest(deleteWorkTypeData.type, deleteWorkTypeDetails);
}

function* watchAdminWorkType() {
  yield takeLatest(fetchAdminWorkType.type, getAdminWorkType);
}

function* watchFetchSelectedAdminWorkTypeList() {
  yield takeLatest(
    fetchSelectedAdminWorkTypetList.type,
    getSelectedAdminWorkTypeList,
  );
}

export function* worktypeSaga() {
  yield all([
    watchWorkTypeDetails(),
    watchAdminWorkType(),
    watchUpdateWorkTypeData(),
    watchAddWorkTypeData(),
    watchDeleteWorkTypeData(),
    watchFetchSelectedAdminWorkTypeList(),
  ]);
}
