import _ from 'lodash';
import * as Yup from 'yup';

const msgs = {
  required: 'This is required field',
  onlyDigits: 'Must be only digits',
  upperCase: 'Must be only Uppercase',
  len: nmbr => `Length must not be more than ${nmbr} digits`,
};

const _required = Yup.string().required(msgs.required);
const _onlyDigits = _required.matches(/^\d+$/, msgs.onlyDigits);
const _test1 = nmbr =>
  _required.test(
    'len',
    msgs.len(nmbr),
    number => String(number).length <= nmbr,
  );
const _test2 = nmbr =>
  _onlyDigits.test(
    'len',
    msgs.len(nmbr),
    number => String(number).length <= nmbr,
  );

const workTypSchema = () => {
  return Yup.object().shape({
    worktype: _required
      .matches(/^[A-Z]+$/, msgs.upperCase)
      .test('len', msgs.len(6), str => String(str).length <= 6),
    worktypeDesc: _test1(50),
    accountNmbr: _test2(20),
    abmsActivity: _test2(3),
    costComponent: _test2(3),
  });
};
export default workTypSchema;
