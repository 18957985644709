import { BuPath } from 'constants/buPath';
import { ROUTES_STRING } from 'constants/routes';
import { ModuleACL, UserType } from 'enums/entitlements.ts';
import { SERVICE_TYPES } from 'enums/serviceTypes.ts';
import { TimesheetStatus, PurchaseOrderStatus } from 'enums/statuses.ts';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { fetchInvoiceUserEntitlements } from 'store/slices/lookupSlice';
import { IsAllowedACL } from 'utils/aclHelper';
import { isEmptyVal } from 'utils/utils';

const ButtonComponent = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(state => state.auth);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);
  const timesheet = useSelector(state => state.timesheetDetail);
  const [allowTimesheetCreation, setAllowTimesheetCreation] = useState(true);
  const [allowGenerateInvoice, setAllowGenerateInvoice] = useState(true);

  const isTablet = useMediaQuery({ minWidth: 576, maxWidth: 1024 });

  const {
    errors,
    handleSubmit,
    onSubmit,
    onError,
    startDateError,
    endDateError,
    weekEndDateError,
    dateOutsidePORange,
    isFutureDate,
    milesCompleteError,
    isActivityError,
    disableFields,
    newTimesheet,
    timesheetApprovals,
    isCostCoordinator,
    invoiceComments,
    setInvoiceComments,
    setShowCancelModal,
    setShowReviewerDisagreeModal,
    handleTimesheetStatus,
    handleGenerateInvoiceModal,
    isJobAuthUnitPriceTask,
  } = props;

  useEffect(() => {
    // Prevent creating timesheet for approved POs
    let showButtons = true;
    const allMatch = purchaseOrder?.poLines?.every(
      obj => obj.itemnum === 'EWO' && ['COMP', 'PAID'].includes(obj.status),
    );

    if (allMatch && purchaseOrder?.poLines?.length) {
      showButtons = false;
    }
    if (!purchaseOrder?.poLines?.length) {
      setAllowGenerateInvoice(false);
    }
    setAllowTimesheetCreation(showButtons);
  }, []);

  const isJobAuthError = () => {
    let error = false;
    if (isJobAuthUnitPriceTask) {
      const upTaskClassification = timesheet?.classificationList?.find(
        classObj => classObj.name === SERVICE_TYPES.UNIT_PRICE_TASK,
      );

      upTaskClassification?.values?.forEach(row => {
        const codeObj = row.find(obj => obj.name === 'code');
        const quantityObj = row.find(obj => obj.name === 'quantity');
        const jobAuthTaskList = timesheet?.jobAuthorization?.jobauthtasks;
        const jobAuthObj = jobAuthTaskList?.find(
          obj => obj.taskcode === codeObj?.value,
        );

        let savedQuantity = 0;
        if (timesheet.timesheetUptasks) {
          for (const timesheetUptask of timesheet.timesheetUptasks) {
            if (timesheetUptask.code == codeObj?.value) {
              savedQuantity = timesheetUptask.quantity;
            }
          }
        }
        const quantityValue = isEmptyVal(quantityObj?.value)
          ? 0
          : Number(quantityObj.value);
        if (
          jobAuthObj?.currentTimesheetsQuantity -
            savedQuantity +
            quantityValue >
            jobAuthObj?.taskUnits ??
          0
        ) {
          error = true;
        }
      });
    }
    return error;
  };

  const displayButton = buttonType => {
    let showButton = false;
    let acl = false;
    if (buttonType === 'accept') {
      acl = IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_approve_timesheet');
    } else if (buttonType === 'disagree') {
      acl = IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_disagree_timesheet');
    } else if (buttonType === 'recall') {
      acl = IsAllowedACL(user, ModuleACL.TIMESHEET, 'can_recall_timesheet');
    }
    const costCoordAcl = IsAllowedACL(
      user,
      ModuleACL.TIMESHEET,
      'can_review_costcord_timesheet',
    );

    // Condition for Accept and Disagree buttons
    if (['accept', 'disagree'].includes(buttonType)) {
      let timesheetApproverIndex = timesheet?.timesheetApprovals.findIndex(
        appr => user?.userId?.toLowerCase() == appr?.approver?.toLowerCase(),
      );
      if (user?.configObject?.userrole === 'admin') {
        showButton = true;
      } else if (
        (acl || costCoordAcl) &&
        ![timesheet?.enteredBy].includes(user?.userId)
      ) {
        if (acl && BuPath.DIST.includes(purchaseOrder?.buPath)) {
          if (
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
            (timesheet?.timesheetApprovals[0].approver === user?.userId ||
              user?.configObject?.allowedsites?.includes(purchaseOrder.site))
          ) {
            showButton = true;
          } else if (
            user?.userId === timesheetApprovals[0]?.approver &&
            timesheet?.caastatus !==
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED
          ) {
            showButton = true;
          } else if (
            (user.userType === UserType.AEP &&
              !timesheet?.submittedByAep &&
              isEmptyVal(timesheetApprovals[0]?.approver) &&
              user?.configObject?.allowedsites?.includes(purchaseOrder.site)) ||
            (user.userType === UserType.VENDOR && timesheet?.submittedByAep)
          ) {
            showButton = true;
          }
        } else if (acl && BuPath.TRANS === purchaseOrder?.buPath) {
          if (user?.userId === timesheetApprovals[0]?.approver) {
            showButton = true;
          }
        } else if (BuPath.GEN.includes(purchaseOrder?.buPath)) {
          if (
            timesheetApproverIndex >= 0 &&
            timesheet?.timesheetApprovals[timesheetApproverIndex].approved ==
              'Y' &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED
          ) {
            timesheetApproverIndex = -1;
          }
          if (
            (timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED &&
              (user?.configObject?.userrole === 'admin' || costCoordAcl)) ||
            (timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
              timesheetApproverIndex !== -1) ||
            (timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED &&
              user?.configObject?.userrole === 'admin') ||
            (timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED &&
              timesheetApproverIndex !== -1)
          ) {
            showButton = true;
          } else if (
            costCoordAcl &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED &&
            (isEmptyVal(purchaseOrder?.costcoord) ||
              purchaseOrder?.costcoord.toLowerCase().startsWith('grp'))
          ) {
            showButton = true;
          }
        } else {
          showButton = false;
        }
      } else if (
        [timesheet?.enteredBy].includes(user?.userId) &&
        costCoordAcl &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED &&
        BuPath.GEN.includes(purchaseOrder?.buPath) &&
        (isEmptyVal(purchaseOrder?.costcoord) ||
          purchaseOrder?.costcoord.toLowerCase().startsWith('grp'))
      ) {
        showButton = true;
      } else if (
        [timesheet?.enteredBy].includes(user?.userId) &&
        costCoordAcl &&
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED &&
        BuPath.GEN.includes(purchaseOrder?.buPath) &&
        user?.userId.toLowerCase() === purchaseOrder?.costcoord.toLowerCase()
      ) {
        showButton = true;
      }
    } else if (buttonType === 'recall') {
      // Condition for Recall button
      if (user?.configObject?.userrole === 'admin') {
        showButton = true;
      } else if (acl) {
        if (BuPath.DIST.includes(purchaseOrder?.buPath)) {
          showButton = true;
        } else if (BuPath.TRANS === purchaseOrder?.buPath) {
          if (
            (user.userType === UserType.AEP && timesheet?.submittedByAep) ||
            (user.userType === UserType.VENDOR && !timesheet?.submittedByAep)
          ) {
            showButton = true;
          }
        } else if (
          BuPath.GEN.includes(purchaseOrder?.buPath) &&
          [timesheet?.enteredBy].includes(user?.userId)
        ) {
          showButton = true;
        } else {
          showButton = false;
        }
      }
    }
    return showButton;
  };

  return (
    <>
      {(newTimesheet ||
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED ||
        timesheet?.caastatus ===
          TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED ||
        timesheet?.caastatus ===
          TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED) &&
        allowTimesheetCreation && (
          <div
            className="aepContainer btnArea"
            style={{ backgroundColor: 'none' }}
          >
            {(user?.configObject?.userrole === 'admin' ||
              (IsAllowedACL(
                user,
                ModuleACL.TIMESHEET,
                'can_update_timesheet',
              ) &&
                (timesheet?.caastatus ===
                TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED
                  ? user?.userId?.toLowerCase() ==
                    timesheet?.enteredBy?.toLowerCase()
                  : true)) ||
              user?.configObject?.userrole === 'admin' ||
              isEmptyVal(timesheet?.submittedByAep) ||
              (user.userType === UserType.AEP && timesheet?.submittedByAep) ||
              (user.userType === UserType.VENDOR &&
                !timesheet?.submittedByAep)) && (
              <>
                <Button
                  variant="primary"
                  disabled={
                    disableFields(null) ||
                    isJobAuthError() ||
                    (isActivityError &&
                      purchaseOrder?.buPath === BuPath.TRANS) ||
                    !purchaseOrder?.poLines?.length
                  }
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  Save
                </Button>
                <Button
                  variant="primary"
                  disabled={
                    !_.isEmpty(errors) ||
                    startDateError ||
                    endDateError ||
                    weekEndDateError ||
                    dateOutsidePORange() ||
                    isFutureDate() ||
                    !isEmptyVal(milesCompleteError) ||
                    !timesheet?.classificationList.length ||
                    isJobAuthError() ||
                    (isActivityError &&
                      purchaseOrder?.buPath === BuPath.TRANS) ||
                    !purchaseOrder?.poLines?.length
                  }
                  onClick={handleSubmit(
                    data => {
                      const tempData = _.cloneDeep(data);
                      tempData['submit'] = true;
                      onSubmit(tempData);
                    },
                    data => onError(data),
                  )}
                >
                  Submit
                </Button>
                {(timesheet?.caastatus ===
                  TimesheetStatus.TIMESHEET_SAVED_NOT_SUBMITTED ||
                  timesheet?.caastatus ===
                    TimesheetStatus.UVL_TIMESHEET_SAVED_NOT_SUBMITTED ||
                  timesheet?.caastatus ===
                    TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED) && (
                  <Button
                    variant="secondary"
                    disabled={disableFields(null)}
                    onClick={() => setShowCancelModal(true)}
                  >
                    Cancel Timesheet
                  </Button>
                )}
              </>
            )}
          </div>
        )}

      {[
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
        TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
        TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
        TimesheetStatus.TIMESHEET_APPROVED,
      ].includes(timesheet?.caastatus) && (
        <div
          className="aepContainer btnArea"
          style={{ backgroundColor: 'none' }}
        >
          {timesheet?.caastatus !== TimesheetStatus.TIMESHEET_APPROVED && (
            <>
              {displayButton('accept') && (
                <Button
                  variant="primary"
                  onClick={handleSubmit(
                    data => {
                      const tempData = data;
                      tempData['accept'] = true;
                      onSubmit(tempData);
                    },
                    data => onError(data),
                  )}
                >
                  Accept
                </Button>
              )}

              {displayButton('disagree') && (
                <Button
                  variant="secondary"
                  onClick={() => setShowReviewerDisagreeModal(true)}
                >
                  Disagree
                </Button>
              )}
            </>
          )}

          {displayButton('recall') && (
            <Button
              variant="secondary"
              onClick={() => handleTimesheetStatus('handleRecall')}
              disabled={
                ![
                  TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED,
                  TimesheetStatus.TIMESHEET_SUBMITTED_NOT_REVIEWED,
                  TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED,
                  TimesheetStatus.TIMESHEET_APPROVED,
                ].includes(timesheet?.caastatus)
              }
            >
              Recall
            </Button>
          )}
        </div>
      )}

      {timesheet?.caastatus === TimesheetStatus.TIMESHEET_APPROVED &&
        IsAllowedACL(
          user,
          ModuleACL.TIMESHEET,
          'can_include_comments_invoice',
        ) && (
          <div
            className="aepContainer"
            style={{
              display: 'flex',
              background: 'none',
              paddingLeft: '0px',
            }}
          >
            <input
              type="checkbox"
              checked={invoiceComments}
              onChange={e => setInvoiceComments(!invoiceComments)}
              style={{ width: '20px', height: '20px' }}
            />
            <span style={{ fontSize: '18px', marginLeft: '12px' }}>
              Include comments from Timesheets into Invoice
            </span>
          </div>
        )}

      {[
        TimesheetStatus.TIMESHEET_APPROVED,
        TimesheetStatus.TIMESHEET_INVOICED,
      ].includes(timesheet?.caastatus) &&
        (!timesheet?.ewoTimesheet || timesheet?.ewoTimesheet == null) && (
          <div className="aepContainer timesheetButtonArea">
            <div
              className="btnArea"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                padding: isTablet ? '42px 0' : '12px 0',
              }}
            >
              {timesheet?.caastatus === TimesheetStatus.TIMESHEET_INVOICED
                ? IsAllowedACL(
                    user,
                    ModuleACL.TIMESHEET,
                    'can_access_invoice',
                  ) && (
                    <Button
                      variant="primary"
                      onClick={e => {
                        dispatch(
                          // @ts-ignore
                          fetchInvoiceUserEntitlements({
                            entitlement: 'CAA-AEP-Invoice-Verifier',
                            callback: () => {
                              navigate(`/${ROUTES_STRING.invoices}`);
                              navigate(
                                `/${ROUTES_STRING.invoices}/${timesheet?.invoiceNmbr}`,
                              );
                            },
                          }),
                        );
                      }}
                      style={{
                        marginLeft: 'auto',
                        marginRight: '24px',
                      }}
                    >
                      Invoice Number: {timesheet?.invoiceNmbr}
                    </Button>
                  )
                : IsAllowedACL(
                    user,
                    ModuleACL.TIMESHEET,
                    'can_generate_invoice',
                  ) &&
                  purchaseOrder.status !==
                    PurchaseOrderStatus.CANCELLED_PENDING_ORDER &&
                  allowGenerateInvoice &&
                  ((user.userType === UserType.VENDOR &&
                    !BuPath.DIST.includes(purchaseOrder?.buPath) &&
                    !purchaseOrder.ewotk) ||
                    user.userType === UserType.AEP ||
                    user?.configObject?.userrole === 'admin') && (
                    <Button
                      variant="primary"
                      onClick={handleGenerateInvoiceModal}
                      style={{
                        marginLeft: 'auto',
                        marginRight: '24px',
                      }}
                    >
                      Generate Invoice
                    </Button>
                  )}
            </div>
          </div>
        )}
    </>
  );
};

export { ButtonComponent };
