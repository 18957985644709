import moment from 'moment';

const ShowEnteredByDate = props => {
  const { enteredBy, enteredDate, updatedBy, updatedDate } = props;
  return (
    <div className="aepContainer">
      <div className="row" style={{ fontSize: '1rem' }}>
        <div className="col-sm-12">
          <div>
            <span>
              {enteredBy && <>Entered By: {enteredBy}</>}
              {enteredDate && (
                <> | Entered Date: {moment(enteredDate).format('MM/DD/YYYY')}</>
              )}
            </span>
          </div>
          <div>
            <span>
              {updatedBy && <>Updated By: {updatedBy}</>}
              {updatedDate && (
                <> | Updated Date: {moment(updatedDate).format('MM/DD/YYYY')}</>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowEnteredByDate;
