import { UserType } from 'enums/entitlements.ts';
import { TimesheetStatus } from 'enums/statuses.ts';
import { Col, Form, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { IsAllowedACL } from 'utils/aclHelper';
import { ModuleACL } from 'enums/entitlements.ts';
import { BuPath } from 'constants/buPath';

const DistributionAccounting = props => {
  const user = useSelector(state => state.auth);
  const timesheet = useSelector(state => state.timesheetDetail);
  const purchaseOrder = useSelector(state => state.purchaseOrderDetails);

  const isMobile = useMediaQuery({ query: '(max-width: 834px)' });

  const { control, trigger, errors, setValue, getValues } = props;

  const isDisabled = () => {
    let condition = false;

    if (
      [
        TimesheetStatus.TIMESHEET_APPROVED,
        TimesheetStatus.TIMESHEET_INVOICED,
      ].includes(timesheet?.caastatus)
    ) {
      condition = true;
    } else {
      const timesheetApproverIndex = timesheet?.timesheetApprovals.findIndex(
        appr => user?.userId?.toLowerCase() == appr?.approver?.toLowerCase(),
      );
      if (user?.configObject?.userrole === 'admin') {
        condition = false;
      } else {
        if (
          (timesheet?.caastatus ===
            TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED &&
            timesheet?.enteredBy?.toLowerCase() ===
              user?.userId?.toLowerCase()) ||
          (user.userType === UserType.VENDOR &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
            BuPath.DIST.includes(purchaseOrder?.buPath) &&
            !timesheet?.submittedByAep) ||
          (user.userType === UserType.AEP &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
            BuPath.DIST.includes(purchaseOrder?.buPath) &&
            timesheet?.submittedByAep)
        ) {
          condition = true;
        } else if (
          (timesheet?.caastatus ===
            TimesheetStatus.TIMESHEET_RETURNED_NOT_APPROVED &&
            timesheetApproverIndex !== -1) ||
          (user.userType === UserType.VENDOR &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
            BuPath.DIST.includes(purchaseOrder?.buPath) &&
            timesheet?.submittedByAep) ||
          (user.userType === UserType.AEP &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_SUBMITTED_NOT_APPROVED &&
            BuPath.DIST.includes(purchaseOrder?.buPath) &&
            timesheet?.submittedByAep)
        ) {
          condition = false;
        } else if (
          (user.userType === UserType.VENDOR &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
            timesheet?.submittedByAep) ||
          (user.userType === UserType.AEP &&
            timesheet?.caastatus ===
              TimesheetStatus.TIMESHEET_RETURNED_NOT_SUBMITTED &&
            !timesheet?.submittedByAep)
        ) {
          condition = true;
        }
      }
    }
    return condition;
  };

  return (
    <>
      <div className="aepContainer">
        <div className="headerContainer">Accounting:</div>
        <div
          style={
            isMobile
              ? { display: 'flex', marginLeft: '15px' }
              : { display: 'flex' }
          }
        >
          <Row style={isMobile ? { flexDirection: 'column' } : {}}>
            <Col>
              <Form.Group>
                <Form.Label>GLBU</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.glbu"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e => {
                        setValue('timesheetAccounts.0.glbu', e.target.value);
                        trigger('timesheetAccounts.0.glbu');
                      }}
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
                <div className="errorMessage">
                  {errors?.['timesheetAccounts']?.[0]?.['glbu']?.['message']}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Department</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.dept"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e => {
                        setValue('timesheetAccounts.0.dept', e.target.value);
                        trigger('timesheetAccounts.0.dept');
                      }}
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
                <div className="errorMessage">
                  {errors?.['timesheetAccounts']?.[0]?.['dept']?.['message']}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Account Number</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.accountNmbr"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={getValues('timesheetAccounts.0.accountNmbr')}
                      onChange={e => {
                        isNaN(parseInt(e.target.value))
                          ? setValue('timesheetAccounts.0.accountNmbr', null)
                          : setValue(
                              'timesheetAccounts.0.accountNmbr',
                              parseInt(e.target.value),
                            );
                        trigger('timesheetAccounts.0.accountNmbr');
                      }}
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
                <div className="errorMessage">
                  {
                    errors?.['timesheetAccounts']?.[0]?.['accountNmbr']?.[
                      'message'
                    ]
                  }
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="distWorkOrder">
                <Form.Label>Work Order</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.workorder"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e =>
                        setValue(
                          'timesheetAccounts.0.workorder',
                          e.target.value,
                        )
                      }
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Cost Component</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.costComponent"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e =>
                        setValue(
                          'timesheetAccounts.0.costComponent',
                          e.target.value,
                        )
                      }
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>ABMS Activity</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.abmsActivity"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e =>
                        setValue(
                          'timesheetAccounts.0.abmsActivity',
                          e.target.value,
                        )
                      }
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Project Id</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.abmsProject"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e =>
                        setValue(
                          'timesheetAccounts.0.abmsProject',
                          e.target.value,
                        )
                      }
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>PCBU</Form.Label>
                <Controller
                  control={control}
                  name="timesheetAccounts.0.projectBusinessUnit"
                  defaultValue=""
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Form.Control
                      value={value}
                      onChange={e =>
                        setValue(
                          'timesheetAccounts.0.projectBusinessUnit',
                          e.target.value,
                        )
                      }
                      ref={ref}
                      type="text"
                      disabled={isDisabled()}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export { DistributionAccounting };
