import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Table, Button } from 'react-bootstrap';
import {
  fetchRDADetails,
  setRdaData,
} from 'store/slices/Invoice/invoiceDetailSlice';

import {
  displayError,
  displaySuccess,
} from 'components/common/Alert/ToastAlert';
import ClipLoader from 'react-spinners/ClipLoader';
import _ from 'lodash';
const AccountingSection = props => {
  let {
    data,
    selectedCheckboxes,
    invoiceAmount,
    handleCheckboxChange,
    activityNum,
    canEditActivity,
  } = props;

  canEditActivity = _.isNil(canEditActivity) ? true : canEditActivity;
  let costLine = data;

  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const dispatch = useDispatch();
  const rdaData = useSelector(state => state.invoiceDetails.allCostLine);

  const handleBlur = (e, index, field) => {
    const inputValue = e.target.value;

    // Check if the input value is a whole number
    if (/^\d+$/.test(inputValue)) {
      // Append ".00" to the input value
      const newValue = inputValue + '.00';

      // Update the state with the new value
      // For example, you can use setState or dispatch an action to update the value
      // For now, let's just log the new value
      const updatedData = [...costLine];

      //validation for amount
      updatedData[index] = {
        ...updatedData[index],
        [field]: newValue,
      };

      const reqIndex = rdaData.findIndex(item => item.activity === activityNum);
      let tempData = _.cloneDeep(rdaData);

      tempData[reqIndex].costline = updatedData;
      const data = {
        allCostLine: tempData,
      };
      dispatch(setRdaData(data));
    }
  };

  const calculateAmtPercent = (field, value) => {
    let remainingAmount,
      remainingDistributedAmount,
      currentPercent,
      remainingDistributedPercent,
      currentAmount;
    if (field === 'linecost') {
      currentAmount = Number(value);
      remainingAmount = invoiceAmount - value;
      remainingDistributedAmount = remainingAmount / (costLine.length - 1);

      currentPercent = (value * 100) / invoiceAmount;
      remainingDistributedPercent =
        (100 - currentPercent) / (costLine.length - 1);
    } else {
      currentAmount = (value * invoiceAmount) / 100;
      remainingAmount = invoiceAmount - currentAmount;
      remainingDistributedAmount = remainingAmount / (costLine.length - 1);
      currentPercent = Number(value);
      remainingDistributedPercent = (100 - value) / (costLine.length - 1);
    }

    return {
      remainingDistributedAmount: remainingDistributedAmount.toFixed(2),
      currentPercent: currentPercent.toFixed(2),
      remainingDistributedPercent: remainingDistributedPercent.toFixed(2),
      currentAmount: currentAmount.toFixed(2),
    };
  };

  const handleDecimal = val => {
    let value = val.toString();

    if (!value.includes('.')) {
      return `${value}.00`;
    } else {
      const parts = value.split('.');
      if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
        return parts.join('.');
      } else {
        return value;
      }
    }
  };

  const handleChange = (e, index, field) => {
    if (/^[a-zA-Z0-9.]*$/.test(e.target.value)) {
      if (
        field === 'workorder' &&
        costLine[index].abmsProject === '' &&
        costLine[index].accountNmbr === '' &&
        costLine[index].projectBusinessUnit === '' &&
        costLine[index].glbu === '' &&
        costLine[index].costComponent === '' &&
        costLine[index].dept === '' &&
        costLine[index].abmsActivity === ''
      ) {
        setSelectedRow(index);
      } else {
        setSelectedRow();
      }

      let newValue = e.target.value?.toUpperCase();

      if (field === 'linecost' || field === 'percentage') {
        newValue = newValue.replace(/[^0-9.]/g, '');
      }

      const decimalIndex = newValue.indexOf('.');
      if (decimalIndex !== -1 && newValue.length - decimalIndex > 3) {
        newValue = newValue.slice(0, decimalIndex + 3);
      }

      const updatedData = [...costLine];

      //validation for amount
      updatedData[index] = {
        ...updatedData[index],
        [field]: newValue,
      };

      if (field === 'linecost') {
        let currentPercent = (newValue * 100) / invoiceAmount;
        updatedData[index] = {
          ...updatedData[index],
          percentage: Number(currentPercent).toFixed(2),
        };
      }

      if (field === 'percentage') {
        let currentAmount = (newValue * invoiceAmount) / 100;
        updatedData[index] = {
          ...updatedData[index],
          linecost: Number(currentAmount).toFixed(2),
        };
      }

      const temUpdatedData = _.cloneDeep(updatedData);
      let totalCost = 0;
      let totalPerc = 0;
      for (let i = 0; i < temUpdatedData.length; i++) {
        totalCost += Number(temUpdatedData[i].linecost);
        totalPerc += Number(temUpdatedData[i].percentage);
      }
      if (totalCost != invoiceAmount && totalPerc == 100) {
        const remainingDifference = Number(invoiceAmount - totalCost).toFixed(
          2,
        );
        let finalIndex = temUpdatedData.length - 1;
        let finalCal = (
          Number(temUpdatedData[finalIndex].linecost) +
          Number(remainingDifference)
        ).toFixed(2);
        temUpdatedData[finalIndex].linecost = Number(finalCal);
      } else if (totalCost == invoiceAmount && totalPerc != 100) {
        const remainingDifference = Number(100 - totalPerc).toFixed(2);
        let finalIndex = temUpdatedData.length - 1;
        let finalCal = (
          Number(temUpdatedData[finalIndex].percentage) +
          Number(remainingDifference)
        ).toFixed(2);
        temUpdatedData[finalIndex].percentage = Number(finalCal).toFixed(2);
      } else if (totalCost != invoiceAmount && totalPerc != 100) {
        const remainingDifferenceAmt = Number(
          invoiceAmount - totalCost,
        ).toFixed(2);
        let finalIndexAmt = temUpdatedData.length - 1;
        let finalCalAmt = (
          Number(temUpdatedData[finalIndexAmt].linecost) +
          Number(remainingDifferenceAmt)
        ).toFixed(2);

        const remainingDifference = Number(100 - totalPerc).toFixed(2);
        let finalIndex = temUpdatedData.length - 1;
        let finalCal = (
          Number(temUpdatedData[finalIndex].percentage) +
          Number(remainingDifference)
        ).toFixed(2);
        if (finalCal < 0 && field == 'percentage') {
          displayError(
            'The total percentage should not be more than 100 Percent',
          );
          return false;
        }
        if (finalCalAmt < 0 && field == 'linecost') {
          displayError(
            'The total amount distributed for invoice line does not equal to original invoice line amount.',
          );
          return false;
        }
        temUpdatedData[finalIndexAmt].linecost = Number(finalCalAmt);
        temUpdatedData[finalIndex].percentage = Number(finalCal).toFixed(2);
      }
      const reqIndex = rdaData.findIndex(item => item.activity === activityNum);
      let tempData = _.cloneDeep(rdaData);

      tempData[reqIndex].costline = temUpdatedData;
      const data = {
        allCostLine: tempData,
      };
      dispatch(setRdaData(data));
    }
  };

  const handleRDA = (workorder, index) => {
    setLoader(true);

    dispatch(
      // @ts-ignore
      fetchRDADetails({
        workorder: workorder,
        callback: async data => {
          setLoader(false);
          if (data) {
            if (data?.error) {
              displayError(
                data?.error?.Status?.ErrorMessage
                  ? data?.error?.Status?.ErrorMessage
                  : 'Internal Server Error, Please try again',
              );
            } else {
              let existingData = [...costLine];
              let updatedData = {
                activityNum: existingData[index].activityNum,
                workorder: existingData[index].workorder,
                abmsProject: data?.abms_project,
                accountNmbr: data?.account_nmbr,
                projectBusinessUnit: data?.project_business_unit,
                glbu: data?.glbu,
                costComponent: data?.cost_component,
                dept: data?.dept,
                abmsActivity: data?.abms_activity,
                linecost: existingData[index].linecost,
                percentage: existingData[index].percentage,
              };

              const reqIndex = rdaData.findIndex(
                item => item.activity === activityNum,
              );
              let tempData = _.cloneDeep(rdaData);

              tempData[reqIndex].costline[index] = updatedData;
              const costdata = {
                allCostLine: tempData,
              };
              dispatch(setRdaData(costdata));
            }
          } else {
            displayError('No Work Order Data Available');
          }
        },
      }),
    );
  };

  return (
    <div className="accountingContainer">
      {loader && (
        <ClipLoader
          color="#009cde"
          cssOverride={{
            top: '50vh',
            left: '50vw',
            position: 'absolute',
            zIndex: 1,
          }}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
      <Table
        hover
        responsive
        striped
        bordered={false}
        style={{ marginLeft: '0px', border: 'none' }}
      >
        <thead>
          <tr>
            <th></th>
            <th>Work Order:</th>
            <th></th>
            <th>GLBU:</th>
            <th>Department:</th>
            <th>Account Number:</th>
            <th>Cost Component:</th>
            <th>ABMS Activity</th>
            <th>Project ID:</th>
            <th>PCBU: </th>
            <th>Amount</th>
            <th>Per(%)</th>
          </tr>
        </thead>
        <tbody>
          {costLine &&
            costLine.map((item, index) => {
              return (
                <tr key={index} className="table-hover-row">
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedCheckboxes && selectedCheckboxes[index]}
                      onChange={e =>
                        handleCheckboxChange(
                          activityNum,
                          index,
                          e.target.checked,
                        )
                      }
                      style={{ width: '24px' }}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      className="customSelect"
                      type="text"
                      value={item.workorder}
                      name="workorder"
                      onChange={e => handleChange(e, index, 'workorder')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td
                    style={{
                      display: 'flex',
                      borderTop: 'none',
                    }}
                  >
                    <Button
                      className="rdabtn"
                      variant="primary"
                      onClick={() =>
                        handleRDA(costLine[index].workorder, index)
                      }
                      disabled={
                        (selectedRow === index ? false : true) &&
                        !canEditActivity
                      }
                      style={{ padding: '0', height: '40px', marginTop: '2px' }}
                    >
                      RDA
                    </Button>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.glbu}
                      name="glbu"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'glbu')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.dept}
                      name="dept"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'dept')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.accountNmbr}
                      name="accountNmbr"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'accountNmbr')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.costComponent}
                      name="costComponent"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'costComponent')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.abmsActivity}
                      name="abmsActivity"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'abmsActivity')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.abmsProject}
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'abmsProject')}
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={item.projectBusinessUnit}
                      name="projectBusinessUnit"
                      className="customSelect"
                      onChange={e =>
                        handleChange(e, index, 'projectBusinessUnit')
                      }
                      disabled={!canEditActivity}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      step=".01"
                      pattern="[0-9]*[.,]?[0-9]+"
                      //value={Number(item.linecost).toFixed(2)}
                      value={item.linecost}
                      name="linecost"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'linecost')}
                      disabled={!canEditActivity}
                      onBlur={e => handleBlur(e, index, 'linecost')}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      step=".01"
                      value={item.percentage}
                      name="percentage"
                      className="customSelect"
                      onChange={e => handleChange(e, index, 'percentage')}
                      disabled={!canEditActivity}
                      onBlur={e => handleBlur(e, index, 'percentage')}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default AccountingSection;
