import { Form, Modal, Tab, Tabs, Row, Col } from 'react-bootstrap';
import { ButtonArea } from 'components/common//Button/ButtonArea/ButtonArea';
import { Divider } from 'components/common/Divider/Divider';
import { AddIcon } from 'assets/images/AddIcon/AddIcon';
import { DeleteIcon } from 'assets/images/DeleteIcon/DeleteIcon';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import workTypSchema from './formHelpers';

const statusDropDown = [
  { label: '--Select--', value: '' },
  { label: 'Active', value: 'A' },
  { label: 'Inactive', value: 'I' },
];

const reliabilityDropDown = [
  { label: '--Select--', value: '' },
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

const AdminWorkTypeModel = ({
  show,
  workTypeTitle,
  selectedWorkType,
  handleAdminWorkTypeSave,
  handleAdminWorkTypeCancel,
  opstateList,
}) => {
  const tabs = [{ key: 'details', value: 'Details' }];
  const [key, setKey] = useState('details');
  const [workTypeData, setWorkTypeData] = useState(selectedWorkType);

  const schema = workTypSchema();
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    // @ts-ignore
    formState: { errors },
  } = methods;

  useEffect(() => {
    reset();
    setWorkTypeData(selectedWorkType);
    setValue('worktype', selectedWorkType.worktype);
    setValue('worktypeDesc', selectedWorkType.worktypeDesc);
    setValue('accountNmbr', selectedWorkType.accountNmbr);
    setValue('abmsActivity', selectedWorkType.abmsActivity);
    setValue('costComponent', selectedWorkType.costComponent);
  }, [show]);

  const handleWorkTypeChange = (i, e) => {
    const newWorkTypeData = [...workTypeData.worktypeOcs];
    newWorkTypeData[i][e.target.name] = e.target.value;
    setWorkTypeData({ ...workTypeData, worktypeOcs: newWorkTypeData });
  };

  const addFields = () => {
    const newWorkTypeData = [...workTypeData.worktypeOcs];
    newWorkTypeData.push({
      opco: '',
      abmsProject: '',
      workOrder: '',
    });
    setWorkTypeData({ ...workTypeData, worktypeOcs: newWorkTypeData });
  };

  const removeFields = i => {
    const newWorkTypeData = [...workTypeData.worktypeOcs];
    newWorkTypeData.splice(i, 1);
    setWorkTypeData({ ...workTypeData, worktypeOcs: newWorkTypeData });
  };

  const handleChange = e => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'overrideAuthFlag') {
      value = e.target.checked ? 'Y' : 'N';
    }
    setValue(name, value);
    setWorkTypeData({ ...workTypeData, [name]: value });
    trigger(name);
  };

  const handleWorkTypeSubmit = () => handleAdminWorkTypeSave(workTypeData);

  return (
    <Modal
      show={show}
      onHide={() => handleAdminWorkTypeCancel()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton="true">
        <Modal.Title>{workTypeTitle}</Modal.Title>
      </Modal.Header>
      <Divider />
      <div className="aepContainer adminModuleWorkTypeDetailContainer">
        <Tabs id="poPage" activeKey={key} onSelect={k => setKey(k)}>
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={tab.value} key={index}>
              <div className="poDetailsContainer">
                <FormProvider {...methods}>
                  <Form>
                    <Row>
                      <Col sm={3}>
                        <Form.Group controlId="form.workTypeLists">
                          <Form.Label>
                            Work Type:
                            <span
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                marginLeft: '5px',
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="worktype"
                            value={workTypeData.worktype}
                            onChange={e => handleChange(e)}
                            style={{ width: '100%' }}
                          />
                          <div className="errorMessage">
                            {errors?.['worktype']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col sm={3}>
                        <Form.Group controlId="form.workDesc">
                          <Form.Label>
                            Work Description:
                            <span
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                marginLeft: '5px',
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="worktypeDesc"
                            value={workTypeData.worktypeDesc}
                            onChange={e => handleChange(e)}
                            className="workListLabel"
                          />
                          <div className="errorMessage">
                            {errors?.['worktypeDesc']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.workDesc">
                          <Form.Label>
                            Account Number:
                            <span
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                marginLeft: '5px',
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="accountNmbr"
                            value={workTypeData.accountNmbr}
                            onChange={e => handleChange(e)}
                            className="workListLabel"
                          />
                          <div className="errorMessage">
                            {errors?.['accountNmbr']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.workDesc">
                          <Form.Label>
                            ABMS Activity:
                            <span
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                marginLeft: '5px',
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="abmsActivity"
                            value={workTypeData.abmsActivity}
                            onChange={e => handleChange(e)}
                            className="workListLabel"
                          />
                          <div className="errorMessage">
                            {errors?.['abmsActivity']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.workcategory">
                          <Form.Label>Worktype Category:</Form.Label>
                          <Form.Select
                            aria-label="workType Category"
                            value={workTypeData.worktypeCategory}
                            onChange={e => handleChange(e)}
                            name="worktypeCategory"
                            className="workListLabel"
                          >
                            <option value="">-Select-</option>
                            {opstateList?.CT?.map((type, index) => {
                              return (
                                type.worktype_category.trim() && (
                                  <option
                                    key={index}
                                    value={type.worktype_category}
                                  >
                                    {type.worktype_category}
                                  </option>
                                )
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.orgGroup">
                          <Form.Label>Org Group:</Form.Label>
                          <Form.Select
                            aria-label="Org Group"
                            value={workTypeData.orgDeptGroup}
                            onChange={e => handleChange(e)}
                            name="orgDeptGroup"
                            className="workListLabel"
                          >
                            <option value="">-Select-</option>
                            {opstateList?.worktype_org_group?.map(
                              (type, index) => (
                                <option key={index} value={type.label}>
                                  {type.label}
                                </option>
                              ),
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.status">
                          <Form.Label>Status:</Form.Label>
                          <Form.Select
                            aria-label="Status"
                            value={workTypeData.status}
                            onChange={e => handleChange(e)}
                            name="status"
                            className="workListLabel"
                          >
                            {statusDropDown.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.planningCategory">
                          <Form.Label>Planning Category:</Form.Label>
                          <Form.Select
                            aria-label="Planning Category"
                            value={workTypeData.planningCategory}
                            onChange={e => handleChange(e)}
                            name="planningCategory"
                            className="workListLabel"
                          >
                            <option value="">-Select-</option>
                            {opstateList?.worktype_planning_category?.map(
                              (type, index) => (
                                <option key={index} value={type.label}>
                                  {type.label}
                                </option>
                              ),
                            )}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.costComponent">
                          <Form.Label>
                            Cost Component:
                            <span
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                marginLeft: '5px',
                              }}
                            >
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="costComponent"
                            value={workTypeData.costComponent}
                            onChange={e => handleChange(e)}
                            className="workListLabel"
                          />
                          <div className="errorMessage">
                            {errors?.['costComponent']?.['message']}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group controlId="form.reliabilityWork">
                          <Form.Label>Reliability Work:</Form.Label>
                          <Form.Select
                            aria-label="Reliability Work"
                            onChange={e => handleChange(e)}
                            name="reliabilityWork"
                            value={workTypeData.reliabilityWork}
                            className="workListLabel"
                          >
                            {reliabilityDropDown.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col sm={6}>
                        <Form className="stormIndicator">
                          <Form.Label className="responseLabel">
                            Storm Indicator:
                          </Form.Label>
                          <Form.Group className="responseForm">
                            <Form.Check
                              inline
                              value="NON"
                              type="radio"
                              aria-label="radio 1"
                              label="No Storm"
                              onChange={e => handleChange(e)}
                              checked={workTypeData.stormIndicator === 'NON'}
                              className="input1"
                              name="stormIndicator"
                            />
                            <Form.Check
                              inline
                              value="MIN"
                              type="radio"
                              aria-label="radio 2"
                              label="Minor Storm"
                              onChange={e => handleChange(e)}
                              checked={workTypeData.stormIndicator === 'MIN'}
                              className="input1"
                              name="stormIndicator"
                            />
                            <Form.Check
                              inline
                              value="MAJ"
                              type="radio"
                              aria-label="radio 2"
                              label="Major Storm"
                              onChange={e => handleChange(e)}
                              checked={workTypeData.stormIndicator === 'MAJ'}
                              className="input1"
                              name="stormIndicator"
                            />
                          </Form.Group>
                        </Form>
                      </Col>
                      <Col sm={6}>
                        <Form>
                          <Form.Label className="responseLabel">
                            Override Authorization:
                          </Form.Label>
                          <Form.Group className="responseForm">
                            <Form.Check
                              inline
                              type="checkbox"
                              aria-label="authorization"
                              name="overrideAuthFlag"
                              onChange={e => handleChange(e)}
                              checked={
                                workTypeData.overrideAuthFlag === 'Y'
                                  ? true
                                  : false
                              }
                              className="input1"
                            />
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <Divider />
                    <br />
                    <Row>
                      <Col sm={10}>OPCO Information</Col>
                      <Col sm={2}>
                        <div style={{ float: 'right' }}>
                          <span onClick={e => addFields()} title="Add Row">
                            <AddIcon />
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {workTypeData.worktypeOcs.map((element, index) => (
                          <Row>
                            <Col sm={3}>
                              <Form.Group
                                style={{
                                  width: '100%',
                                  marginRight: '12px',
                                }}
                              >
                                <Form.Label>Operating Company State</Form.Label>
                                <Form.Select
                                  value={element?.opco || ''}
                                  onChange={e => handleWorkTypeChange(index, e)}
                                  name="opco"
                                  style={{ width: '95%' }}
                                >
                                  <option value="">--Select--</option>
                                  {opstateList?.OP?.map((type, index) => (
                                    <option key={index} value={type.opcostate}>
                                      {type.opcostate}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col sm={3}>
                              <Form.Group
                                style={{
                                  width: '100%',
                                  marginRight: '12px',
                                }}
                              >
                                <Form.Label>Project ID </Form.Label>
                                <Form.Select
                                  value={element.abmsProject || ''}
                                  onChange={e => handleWorkTypeChange(index, e)}
                                  name="abmsProject"
                                  style={{ width: '95%' }}
                                >
                                  <option value="">--Select--</option>
                                  {opstateList?.PR?.map((type, index) => (
                                    <option
                                      key={index}
                                      value={type.abmsProject}
                                    >
                                      {type.abmsProject}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col sm={3}>
                              <Form.Group
                                style={{
                                  width: '100%',
                                  marginRight: '12px',
                                }}
                              >
                                <Form.Label>Work Order:</Form.Label>
                                <Form.Select
                                  value={element.workOrder || ''}
                                  onChange={e => handleWorkTypeChange(index, e)}
                                  name="workOrder"
                                  style={{ width: '95%' }}
                                >
                                  <option value="">--Select--</option>
                                  {opstateList?.WO?.map((type, index) => (
                                    <option key={index} value={type.workorder}>
                                      {type.workorder}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col sm={2}>
                              <Form.Group
                                style={{
                                  width: '100%',
                                  marginRight: '12px',
                                  marginTop: '12px',
                                }}
                              >
                                {index > 0 && (
                                  <span
                                    style={{ width: '100%' }}
                                    onClick={e => removeFields(index)}
                                    title="Delete Row"
                                  >
                                    <DeleteIcon />
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    </Row>
                  </Form>
                </FormProvider>
                <div></div>
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
      <Modal.Footer>
        <ButtonArea
          primaryLabel="Save"
          secondaryLabel="Cancel"
          primaryOnClick={handleSubmit(handleWorkTypeSubmit)}
          secondaryOnClick={() => handleAdminWorkTypeCancel()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export { AdminWorkTypeModel };
