import excelJs from 'exceljs';
import moment from 'moment';

const defaultWidth = 20;
export const exportBidrateSheet = async data => {
  const workbook = new excelJs.Workbook();

  const worksheet = workbook.addWorksheet(data.worksheet, {
    pageSetup: {
      showGridLines: true,
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 0,
      orientation: 'landscape',
      paperSize: 5, //legal size
    },
  });

  //Adding bidrate number
  worksheet.getCell('A2').value = 'Bid Rate Sheet Record No.:';
  worksheet.getCell('A2').font = { bold: true };
  worksheet.getCell('B2').value = data.bidrateNmbr || 'new';

  //Adding contractor name
  worksheet.getCell('A3').value = 'Contractor Name:';
  worksheet.getCell('A3').font = { bold: true };
  worksheet.getCell('B3').value = data.contractorname;
  worksheet.getCell('B3').alignment = { wrapText: true };

  ////Adding bidrate Name:
  worksheet.getCell('A4').value = 'Name:';
  worksheet.getCell('A4').font = { bold: true };
  worksheet.getCell('B4').value = data.formName;

  //Adding Type:
  worksheet.getCell('A5').value = 'Type:';
  worksheet.getCell('A5').font = { bold: true };
  worksheet.getCell('B5').value = data.type;
  //Title
  worksheet.getCell('A8').value = data.title;
  worksheet.getCell('A8').font = { bold: true };
  generateExcelRecords(
    worksheet,
    data.headersValues,
    data.records,
    data.serviceType,
  );

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const timeData = new Date();
  link.download = `${data.bidratename} ${data.title}.xlsx`;
  link.click();

  //   return generateExcelRecords(worksheet, data.headersValues, data.records);
};

const generateExcelRecords = (
  worksheet,
  headersValues,
  recordsoriginal,
  serviceType,
) => {
  if (!recordsoriginal) {
    worksheet.getRow(9).values = ['No records found'];
    return worksheet;
  }
  const records = JSON.parse(JSON.stringify(recordsoriginal));
  const headers = [];
  const columns = [];
  for (const header of headersValues) {
    headers.push(header.value);
    // Dynamic width
    const columnWidth = generateDynamicWidth(header.key, records);
    columns.push({
      key: header.key,
      width: columnWidth > defaultWidth ? columnWidth + 4 : defaultWidth,
    });
  }

  if (
    serviceType === 'bidrateLaborRates' ||
    serviceType === 'bidrateUnionRates'
  ) {
    columns[0].width = columns[0].width > 26 ? columns[0].width : 26;
  } else if (serviceType === 'bidrateEquipmentRates') {
    columns[0].width = columns[0].width > 26 ? columns[0].width : 26;
    columns[1].width = columns[1].width > 24 ? columns[1].width : 24;
  } else if (serviceType === 'bidrateReimbursibleMarkups') {
    columns[0].width = columns[0].width > 27 ? columns[0].width : 27;
    columns[1].width = columns[1].width > 31 ? columns[1].width : 31;
  } else if (serviceType === 'bidrateUnitPrices') {
    columns[0].width = columns[0].width > 26 ? columns[0].width : 26;
    columns[1].width = columns[1].width > 24 ? columns[1].width : 24;
  } else if (serviceType === 'bidrateCus') {
    columns[0].width = columns[0].width > 33 ? columns[0].width : 33;
    columns[1].width = columns[1].width > 41 ? columns[1].width : 41;
  } else if (serviceType === 'bidrateUndergrndMltplrRates') {
    columns[0].width = columns[0].width > 28 ? columns[0].width : 28;
    columns[1].width = columns[1].width > 36 ? columns[1].width : 36;
  }
  //Add Headers
  worksheet.getRow(9).values = headers;

  worksheet.getRow(9).font = {
    family: 2,
    bold: true,
    width: defaultWidth,
  };
  worksheet.getRow(9).alignment = {
    wrapText: true,
  };
  //Print Changes - Repeat row 9 on every page (header)
  worksheet.pageSetup.printTitlesRow = '2:9';
  worksheet.columns = columns;

  //load data
  for (const record of records) {
    // Checking dates
    for (const dataRecord in record) {
      if (record[dataRecord] !== null) {
        if (
          !isNaN(record[dataRecord]) &&
          record[dataRecord] !== '' &&
          !dataRecord.toLowerCase().includes('code')
        ) {
          record[dataRecord] = Number(record[dataRecord]);
        } else if (
          dataRecord.toLowerCase().includes('date') &&
          record[dataRecord] &&
          !isNaN(Date.parse(record[dataRecord]))
        ) {
          const isDate = moment(record[dataRecord]);
          if (isDate.isValid()) {
            record[dataRecord] = new Date(
              new Date(record[dataRecord]).toISOString(),
            );
          }
        }
      }
    }
    worksheet.addRow(record);
  }
  return worksheet;
};

const generateDynamicWidth = (key, rows) => {
  let widthCount = [];
  for (const row of rows) {
    const castString = String(row[key]);
    if (castString?.length > defaultWidth) {
      widthCount.push(castString.length);
    }
  }
  return widthCount.length > 0 ? Math.max(...widthCount) : 0;
};
